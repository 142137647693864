/**
 * Created by jerry on 2020/03/30.
 * 产品消耗提成 api
 */
import * as API from '@/api/index'

export default {
    // 获取产品消耗组织单位提成方案列表
    getTreatProductCommissionScheme: params => {
        return API.POST('api/treatProductCommissionScheme/list', params)
    },
    // 创建产品消耗组织单位提成方案
    createTreatProductCommissionScheme: params => {
        return API.POST('api/treatProductCommissionScheme/create', params)
    },
    //  删除产品消耗提成方案
    deleteTreatProductCommissionScheme: params => {
        return API.POST('api/treatProductCommissionScheme/delete', params)
    },
    //  获取产品消耗分类提佣方案
    getTreatProductCategoryCommission: params => {
        return API.POST('api/treatProductCategoryCommission/all', params)
    },
    //  保存产品消耗分类提成方案
    updateTreatProductCategoryCommission: params => {
        return API.POST('api/treatProductCategoryCommission/update', params)
    },
    //  获取产品消耗分类经手人提佣方案
    getTreatProductCategoryHandlerCommission: params => {
        return API.POST('api/treatProductCategoryHandlerCommission/all', params)
    },
    //  保存产品消耗分类经手人提成方案
    updateTreatProductCategoryHandlerCommission: params => {
        return API.POST('api/treatProductCategoryHandlerCommission/update', params)
    },
    //  获取分类下的产品提佣方案
    getTreatProductCommission: params => {
        return API.POST('api/treatProductCommission/all', params)
    },
    //  保存产品消耗提成方案
    updateTreatProductCommission: params => {
        return API.POST('api/treatProductCommission/update', params)
    },
    //  获取产品下经手人提佣方案
    getTreatProductHanderCommission: params => {
        return API.POST('api/treatProductHandlerCommission/all', params)
    },
    //  保存产品下经手人提成方案
    updateTreatProductHanderCommission: params => {
        return API.POST('api/treatProductHandlerCommission/update', params)
    },
    //  获取所有产品经手人提佣方案
    treatProductSchemeHanderCommission: params => {
        return API.POST('api/treatProductSchemeHandlerCommission/all', params)
    },
    //  保存所有产品经手人提成方案
    treatProductSchemeHanderCommissionupdate: params => {
        return API.POST('api/treatProductSchemeHandlerCommission/update', params)
    },
}